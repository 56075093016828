import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { MODAL_DATA } from '@dougs/ds';
import { MailPreview } from '@dougs/task/dto';
import { TemplateTaskStateService } from '@dougs/task/shared';

@Injectable()
export class EmailPreviewModalComponentService {
  constructor(
    @Inject(MODAL_DATA) public data: { rootId: number; companyId: number },
    private readonly templateTaskState: TemplateTaskStateService,
  ) {
    this.triggerPreviewLoading(this.data.rootId, this.data.companyId);
  }

  private readonly mailPreviewHasBeenCalled: WritableSignal<boolean> = signal(false);
  mailPreviewHasBeenCalled$: Signal<boolean> = this.mailPreviewHasBeenCalled.asReadonly();

  private readonly mailPreview: WritableSignal<MailPreview | null> = signal<MailPreview | null>(null);
  mailPreview$: Signal<MailPreview | null> = this.mailPreview.asReadonly();

  hasError$: Signal<boolean> = computed(() => this.mailPreview$() === null && this.mailPreviewHasBeenCalled$());

  public async getMailPreview(taskId: number, companyId: number): Promise<void> {
    this.mailPreview.set(await this.templateTaskState.getMailPreview(taskId, companyId));
    this.mailPreviewHasBeenCalled.set(true);
  }
  private triggerPreviewLoading(taskId: number, companyId: number): void {
    void this.getMailPreview(taskId, companyId);
  }
}
